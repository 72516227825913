import React, { Component } from 'react'
import { useEffect, useRef } from 'react';
import { IChartingLibraryWidget, widget, ChartingLibraryWidgetOptions, LanguageCode } from "../charting_library";
import DataFeed from "./dataFeed";
import './index.css'

interface TVchartsProps {
    symbol: string,
    group: string
}

const TVcharts: React.FC<TVchartsProps> = (props) => {
    const myWeight = useRef<IChartingLibraryWidget>();
    let isChartReady = false
    useEffect(() => {
        // console.log(myWeight.current);
        if (!myWeight.current) {
            myWeight.current = new widget({
                symbol: props.symbol,
                locale: 'en',
                container: 'tv-chart_container',
                interval: '60' as ChartingLibraryWidgetOptions['interval'],
                datafeed: new DataFeed(),
                library_path: '/charting_library/',
                timezone: "Asia/Shanghai",
                theme: 'Dark'
            });
        }
        myWeight.current.onChartReady(() => {

            isChartReady = true

            myWeight.current?.chart().setSymbol('SEELE_USDT', () => {
                // console.log('切换成功');
            })

        })
        if (isChartReady) {
            myWeight.current?.chart().setSymbol(props.symbol, () => {
                // console.log('切换成功');
            })

        }

    }, [props.symbol])

    useEffect(()=>{
            myWeight.current = new widget({
                symbol: props.symbol,
                locale: props.group as ChartingLibraryWidgetOptions['locale'],
                container: 'tv-chart_container',
                interval: '60' as ChartingLibraryWidgetOptions['interval'],
                datafeed: new DataFeed(),
                library_path: '/charting_library/',
                timezone: "Asia/Shanghai",
                theme: 'Dark'
            });
    },[ props.group])

    return <div className='c-charting' id='tv-chart_container'></div>
}
export default TVcharts