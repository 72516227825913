import React, { useEffect, useState, useRef } from 'react';
import './app.scss';
import {
  Show,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select, Button, Box
} from '@chakra-ui/react'
import { PhoneIcon, Search2Icon, ChevronDownIcon, AttachmentIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { ChakraProvider } from '@chakra-ui/react'

import { useTranslation } from 'react-i18next'

import TVcharts from './components/index'

import axios from 'axios'

import _ from 'lodash'

import logo from './assets/logo.png'
import seelelogo from './assets/seelen.png'
import snplogo from './assets/snp.png'
import csflogo from './assets/usdt.png'

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

function formatDate(date: Date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}

type TradeItem = {
  Op: string;
  Amount0: number;
  Amount1: number;
  Price: number;
  TxHash: string;
  Address: string;
  Time: number;

}

type SymbolItem = {
  Symbol: string;
  Quote: string;
  Token: string;
  Precision: number;
  InfoEn: string;
  InfoZh: string;
}

const App: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [data, setData] = useState<TradeItem[]>([])
  const [symbol, setSymbol] = useState('SEELE_USDT')
  const [group, setGroup] = useState('en');
  const [list, setList] = useState<SymbolItem[]>([]);
  const [obj, setObj] = useState('0x0000000000000000000000000000000000000000');
  const [state, setState] = useState(0.00)
  const [state1, setState1] = useState(0.00);
  const [state2, setState2] = useState(0.00)
  const [state3, setState3] = useState(0.00)
  const [statePrice, setStatePrice] = useState(0);
  let items: TradeItem[] = [];

  const APIUrl = "https://candyswap.finance/api/v1/"
  // const APIUrl = "http://localhost:8080/api/v1/"

  // 获取表格数据
  useEffect(() => {
    async function getData() {
      setData([])
      try {
        await axios(APIUrl + `recent/${obj}`).then(res => {
          let data1 = res.data.Data.tradingHistory

          data1.map((item: any) => {
            if (item.Op == 0) {
              item.Op = 'Buy'
            } else {
              item.Op = 'Sell'
            }
            const tradeitem: TradeItem = {
              Time: item.Time,
              Op: item.Op,
              Address: item.Address,
              Amount0: item.Amount0,
              Amount1: item.Amount1,
              Price: item.Price,
              TxHash: item.TxHash
            };
            items.push(tradeitem)
          })
          setStatePrice(items[items.length - 1].Price)
          setData(items.reverse())

        });
      } catch (error) {
        console.log(error);
      }
    }

    getData()

  }, [obj, group]);


  // 定时请求获取新数据
  useEffect(() => {

    let t = 0
    data.map((item: TradeItem, index: any) => {
      if (item.Time > t) {
        t = item.Time + 1
      }
    })
    const timer = setInterval(() => {

      try {
        axios.get(APIUrl + `recent/${obj}?t=${t}`).then(res => {

          if (res.data.Data.tradingHistory) {
            let data2 = res.data.Data.tradingHistory;
            let items: TradeItem[] = [];
            data2.map((item: any) => {

              if (item.Time > t) {
                t = item.Time + 1
              }
              if (item.Op == 0) {

                item.Op = 'Buy'
              } else {
                item.Op = 'Sell'
              }
              const tradeitem: TradeItem = {
                Time: item.Time,
                Op: item.Op,
                Address: item.Address,
                Amount0: item.Amount0,
                Amount1: item.Amount1,
                Price: item.Price,
                TxHash: item.TxHash
              };
              items.push(tradeitem)
            })

            items = items.reverse()
            if (items.length > 0) {
              setStatePrice(items[0].Price)
            }

            data.map((item: TradeItem, index: any) => {
              if (items.length < 200) {
                items.push(item)
              }
            })
            setData(items)
          }
        })
      } catch (error) {
        console.log(error);
      }
    }, 3000);
    return () => clearInterval(timer);
  }, [obj, data]);

  // 获取货币列表
  useEffect(() => {
    async function getList() {
      try {
        await axios(APIUrl + 'symbols').then(res => {
          setList(res.data.Data.symbols)
        })
      } catch (error) {
        console.log(error);
      }
    }
    getList()
  }, [])

  // 跳转页面
  const goCandy = () => {
    const w: any = window.open('about:blank');

    w.location.href = "http://candyswap.finance/swap"
  }

  // 切换语言
  const saveGroup = (event: any) => {
    if (event.target.value === 'en') {
      i18n.changeLanguage('en')
      setGroup('en')
    } else {
      i18n.changeLanguage('zh')
      setGroup('zh');
    }
  }

  // 15 1H 4H 24H价格
  useEffect(() => {
    let isMounted = true;
    try {
      axios.get(APIUrl + `bars/${obj}?res=15&cb=2`).then(res => {
        let data = (res.data.Data.bars[1].Close - res.data.Data.bars[0].Close) * 100 / res.data.Data.bars[0].Close
        if (isMounted) {
          setState(data)
        }
      })
    } catch (error) {
      console.log(error);
    }

    try {
      axios.get(APIUrl + `bars/${obj}?res=60&cb=2`).then(res => {
        let data = (res.data.Data.bars[1].Close - res.data.Data.bars[0].Close) * 100 / res.data.Data.bars[0].Close
        if (isMounted) {
          setState1(data)
        }
      })
    } catch (error) {
      console.log(error);
    }

    try {
      axios.get(APIUrl + `bars/${obj}?res=240&cb=2`).then(res => {
        let data = (res.data.Data.bars[1].Close - res.data.Data.bars[0].Close) * 100 / res.data.Data.bars[0].Close
        if (isMounted) {
          setState2(data)
        }
      })
    } catch (error) {
      console.log(error);
    }

    try {
      axios.get(APIUrl + `bars/${obj}?res=1440&cb=2`).then(res => {
        let data = (res.data.Data.bars[1].Close - res.data.Data.bars[0].Close) * 100 / res.data.Data.bars[0].Close
        if (isMounted) {
          setState3(data)
        }
      })
    } catch (error) {
      console.log(error);
    }
    return () => { isMounted = false }
  }, [obj, statePrice]);

  // 点击事件
  const getdata = _.debounce(() => {
    setObj('0x0000000000000000000000000000000000000000')
    setSymbol('SEELE_USDT')
  }, 500, { leading: false, trailing: true })
  const getdata1 = _.debounce(() => {
    setObj('0x2ABf07aCfe206A5fa1C4827E011ffCb3ffc75696')
    setSymbol('SNP_USDT')
  }, 500, { leading: false, trailing: true })
  const getdata2 = _.debounce(() => {
    setObj('0x3453c5bEC5dF4278ca0D5b2A60E3F0B6e40c2D58')
    setSymbol('CSF_USDT')
  }, 500, { leading: false, trailing: true })


  // const getData = (val?: string) => {
  //   switch (val) {
  //     case '1':
  //       setObj('0x0000000000000000000000000000000000000000')
  //       setSymbol('SEELE_USDT')
  //       break;
  //     case '2':
  //       setObj('0x2ABf07aCfe206A5fa1C4827E011ffCb3ffc75696')
  //       setSymbol('SNP_USDT')
  //       break;
  //     case '3':
  //       setObj('0x3453c5bEC5dF4278ca0D5b2A60E3F0B6e40c2D58')
  //       setSymbol('CSF_USDT')
  //       break;
  //   }
  // }

  const shortenAddress = (address = '', num = 3): string =>
    `${address.substring(0, num + 2)}...${address.substring(
      address.length - num)
    }`;

  const tableBodyRender = React.useMemo(
    () => (
      <>
        {
          data && data.length > 0 ? data.map((item: any, index: number) => {
            return (
              <Tr style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px' }} key={item.TxHash} className='slide-in-left'>
                <Td style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px' }} color='#b0b6d0'>{formatDate(new Date(item.Time * 1000))}</Td>
                <Td style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px' }} color={item.Op === 'Buy' ? '#26A69A' : "#EF5350"}>{item.Op}</Td>
                <Td style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px' }} color={item.Op === 'Buy' ? '#26A69A' : "#EF5350"}>{item.Amount0}</Td>
                <Td style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px' }} color={item.Op === 'Buy' ? '#26A69A' : "#EF5350"}>{item.Amount1}</Td>
                <Td style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px' }} color={item.Op === 'Buy' ? '#26A69A' : "#EF5350"}>{"$" + Number(item.Price).toFixed(6)}</Td>
                <Td style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px' }} color={item.Op === 'Buy' ? '#26A69A' : "#EF5350"}><a href={"https://seeleview.net/tx/" + item.TxHash} target="_blank">{shortenAddress(item.TxHash, 3)}</a></Td>
                <Td style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px' }} color={item.Op === 'Buy' ? '#26A69A' : "#EF5350"}><a href={"https://seeleview.net/address/" + item.Address} target="_blank">{shortenAddress(item.Address, 3)}</a></Td>
              </Tr>
            )
          }) : <div>No Data</div>
        }
      </>
    ),
    [data]
  );

  const getTokenInfo = (obj: string, group: string, list: SymbolItem[]): string => {
    let info = ""
    list.map((item: SymbolItem, index: any) => {
      if (item.Token == obj) {
        if (group == 'en') {
          info = item.InfoEn
          return true
        } else {
          info = item.InfoZh
          return true
        }
      }
    })
    return info
  }
  const tokenInfo = React.useMemo(
    () => (
      <p>
        {
          getTokenInfo(obj, group, list)
        }
      </p>
    ),
    [obj, group, list]
  );
  return (
    <ChakraProvider>
      <div className="App">
        <div className='Left'>
          <div className="logo">
            <img src={logo}></img>
          </div>
          <div className="content">
            <Show breakpoint='(max-width: 1000px)'>
              {/*<Search2Icon />*/}
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton backgroundColor='#f0f0f0' isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon />} color='#000'>
                      {symbol}
                    </MenuButton>
                    <MenuList color='#000'>
                      <MenuItem onClick={getdata}>SEELE</MenuItem>
                      <MenuItem onClick={getdata1}>SNP</MenuItem>
                      <MenuItem onClick={getdata2}>CSF</MenuItem>
                    </MenuList>
                  </>
                )}
              </Menu>
            </Show>


            <ul>
              <li onClick={getdata}><img src={seelelogo} ></img>SEELE</li>
              <li onClick={getdata1}><img src={snplogo} ></img>SNP</li>
              <li onClick={getdata2}><img src={csflogo} ></img>CSF</li>
            </ul>
          </div>
        </div>
        <div className='Main'>
          {/* 头部 */}
          <div className="main-top">
            <TVcharts symbol={symbol} group={group} />
            <div className='Right'>
              <div className="right-top">
                <div className="right-top-left">
                  <b>{symbol}</b>
                </div>
                <div className="right-top-right">

                  <Select id='select' variant='solid' backgroundColor='#000' value={group} onChange={saveGroup}>
                    <option value='en' style={{ color: '#6e7791' }}>
                      English
                    </option>
                    <option value='zh' style={{ color: '#6e7791' }}>
                      中文
                    </option>
                  </Select>
                </div>
              </div>
              <div className="right-footer">
                <ul>
                  <li>
                    <p style={{ color: '#6e7791' }}>{t('right.price')}</p>
                    <p><b>{statePrice.toFixed(6) + ' USDT'}</b></p>
                  </li>
                  <li className='li2'>
                    <div>
                      <p style={{ color: '#6e7791' }}>15M</p>
                      {state >= 0 ? (
                        <p style={{ color: '#009651' }}><b>{state.toFixed(2) + '%'}</b></p>
                      ) : (
                        <p style={{ color: '#dc0000' }}><b>{state.toFixed(2) + '%'}</b></p>
                      )
                      }
                    </div>
                    <div>
                      <p style={{ color: '#6e7791' }}>1H</p>
                      {state1 >= 0 ? (
                        <p style={{ color: '#009651' }}><b>{state1.toFixed(2) + '%'}</b></p>
                      ) : (
                        <p style={{ color: '#dc0000' }}><b>{state1.toFixed(2) + '%'}</b></p>
                      )
                      }
                    </div>
                    <div>
                      <p style={{ color: '#6e7791' }}>4H</p>
                      {state2 >= 0 ? (
                        <p style={{ color: '#009651' }}><b>{state2.toFixed(2) + '%'}</b></p>
                      ) : (
                        <p style={{ color: '#dc0000' }}><b>{state2.toFixed(2) + '%'}</b></p>
                      )
                      }
                    </div>
                    <div>
                      <p style={{ color: '#6e7791' }}>24H</p>
                      {state3 >= 0 ? (
                        <p style={{ color: '#009651' }}><b>{state3.toFixed(2) + '%'}</b></p>
                      ) : (
                        <p style={{ color: '#dc0000' }}><b>{state3.toFixed(2) + '%'}</b></p>
                      )
                      }
                    </div>
                  </li>
                </ul>
                <div className="swap">
                  <Button leftIcon={<ArrowForwardIcon />} colorScheme='teal' variant='solid' width='90%' color='#000' onClick={goCandy}>
                    {t('right.candyswap')}
                  </Button>
                </div>
                <div className="info">
                  <b>{t("right.info")}</b>
                  <p>
                    {tokenInfo}
                  </p>
                </div>
              </div>
            </div >
          </div>
          {/* 脚部 */}
          <div className="main-footer">
            <Table variant='simple'>
              <Thead style={{ position: 'sticky', top: '0px', zIndex: 1 }}>
                <Tr style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px' }}>
                  <Th style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px', background: '#0f121f' }}>{t('maintable.date')}</Th>
                  <Th style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px', background: '#0f121f' }} >{t('maintable.type')}</Th>
                  <Th style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px', background: '#0f121f' }}>{symbol.split('_')[0]}</Th>
                  <Th style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px', background: '#0f121f' }}>{symbol.split('_')[1]}</Th>
                  <Th style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px', background: '#0f121f' }}>{t('maintable.price')}</Th>
                  <Th style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px', background: '#0f121f' }}>txn</Th>
                  <Th style={{ borderColor: '#cccccc', borderWidth: '0px 1px 0px 0px', background: '#0f121f' }}>{t('maintable.sender')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableBodyRender}
              </Tbody>
            </Table>
          </div>
        </div>
      </div>
    </ChakraProvider>
  );
}

export default App;
