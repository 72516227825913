// 引入axios
import axios from 'axios'

import { IExternalDatafeed, ErrorCallback, HistoryCallback, IDatafeedChartApi, LibrarySymbolInfo, PeriodParams, ResolutionString, ResolveCallback, SearchSymbolsCallback, SubscribeBarsCallback, SymbolResolveExtension, OnReadyCallback } from '../charting_library'

let click: any;
let lastBar: any;
const APIUrl = "https://chart.candyswap.finance/api/v1/"

class DataFeed implements IDatafeedChartApi, IExternalDatafeed {

    // 参数配置
    public onReady(callback: OnReadyCallback): void {
        setTimeout(() => {
            callback(
                {
                    exchanges: [],
                    symbols_types: [],
                    supports_time: true,
                    supports_marks: false,
                    supports_timescale_marks: false,
                    supported_resolutions: ['5', '15', '60', "4H", '1D'] as any,
                }
            );
        }, 0);
    }
    public searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback): void {
        console.log(userInput, exchange, symbolType, onResult)
    }

    public getPeriod(type: string) {
        return {
            "1": "1",
            "5": "5",
            "15": "15",
            "30": "30",
            "60": "60",
            "240": "240",
            "1D": "1440",
            "1W": "10080"
        }[type]
    }

    public getPeriodNum(type: string):number {
        switch (type) {
            case "1":
            return 1;
            case "5":
            return 5;
            case "15":
            return 15;
            case "30":
            return 30;
            case "60":
            return 60;
            case "240":
            return 240;
            case "1D":
            return 1440;
            case "1W":
            return 10080;
        }
        return 1
    }

    public getSymbolAddress(type: string):string {
        switch (type) {
            case "SEELE_USDT":
            return '0x0000000000000000000000000000000000000000';
            case "SNP_USDT":
                return '0x2ABf07aCfe206A5fa1C4827E011ffCb3ffc75696';
            case "CSF_USDT":
                return '0x3453c5bEC5dF4278ca0D5b2A60E3F0B6e40c2D58';
        }
        return '0x0000000000000000000000000000000000000000';
    }

    public resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback, extension?: SymbolResolveExtension | undefined): void {
        setTimeout(function () {
            onResolve({
                name: symbolName,
                full_name: symbolName,
                timezone: "Asia/Shanghai", //默认时区
                minmov: 1,
                fractional: false,
                //设置周期 等于所有时间都是交易时段
                session: "24x7",
                has_intraday: true, //布尔值显示商品是否具有日内（分钟）历史数据
                has_no_volume: false,
                //设置是否支持周月线
                has_daily: true,
                //设置是否支持周月线
                has_weekly_and_monthly: true,
                description: symbolName,
                //设置精度  100表示保留两位小数   1000三位   10000四位
                pricescale: 1000000,
                ticker: symbolName,
                supported_resolutions: ['5', '15', '60', "4H", '1D'],
                // seconds_multipliers: ["1S", "5S", "15S"],
                // volume_precision: 0, //整数显示此商品的成交量数字的小数位。0表示只显示整数。1表示保留小数位的1个数字字符
                // data_status: "streaming", //数据状态(streaming(实时),endofday(已收盘),pulsed(脉冲),delayed_streaming(延迟流动中))
            } as any)
        }, 0);
    }

    // 获取图表数据
    public getBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, periodParams: PeriodParams, onResult: HistoryCallback, onError: ErrorCallback): void {
        if (periodParams.firstDataRequest) {
            try {
            axios.get(APIUrl+`bars/${this.getSymbolAddress(symbolInfo.name)}?from=${periodParams.from}&to=${periodParams.to}&res=${this.getPeriod(resolution)}&cb=${periodParams.countBack}`).then(res => {
                let data = res.data.Data.bars
                
                onResult(data.map((p: any) => {
                    p.time = p.Time*1000;
                    p.open = p.Open;
                    p.close = p.Close;
                    p.low = p.Low;
                    p.high = p.High;
                    p.volume = p.Volume;
                    return p
                }), { noData: false })
                lastBar = data[data.length - 1];
            })
            } catch (error) {
                console.log(error);
            }

        } else {
            // console.log(periodParams);
            try {
                axios.get(APIUrl+`bars/${this.getSymbolAddress(symbolInfo.name)}?from=${periodParams.from}&to=${periodParams.to}&res=${this.getPeriod(resolution)}&cb=${periodParams.countBack}`).then(res => {
                    let data = res.data.Data.bars
                    //console.log(data);
                    if (data){
                        onResult(data.map((p: any) => {
                            p.time = p.Time*1000;
                            p.open = p.Open;
                            p.close = p.Close;
                            p.low = p.Low;
                            p.high = p.High;
                            p.volume = p.Volume;
                            return p
                        }), { noData: false })
                    }else{
                        onResult([], { noData: true });
                    }
                })
            } catch (error) {
                console.log(error);
            }
            // onResult([], { noData: true });

        }
    }

    // 订阅数据
    public subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, onResetCacheNeededCallback: () => void): void {

        console.log(listenerGuid,resolution,symbolInfo);
        

        clearInterval(click)
        
        click = setInterval(() => {
            
            try {
                axios.get(APIUrl+`bars/${this.getSymbolAddress(symbolInfo.name)}?from=${lastBar.Time}&to=${Math.floor(Date.now() / 1000)}&res=${this.getPeriod(resolution)}&cb=2`).then(res => {
                    res.data.Data.bars.map((p: any) => {
                        // console.log(lastBar.Time>p.Time);
                        if(p.Time>=lastBar.Time){
                            p.time = p.Time*1000;
                            p.open = p.Open;
                            p.close = p.Close;
                            p.low = p.Low;
                            p.high = p.High;
                            p.volume = p.Volume;
                            lastBar = p;
                            onTick(p)
                        }
                    })
                })
            } catch (error) {
                console.log(error);
            }
        }, 5000)
    }

    public unsubscribeBars(listenerGuid: string): void {
        //console.log("取消订阅");
    }
}

export default DataFeed